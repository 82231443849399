/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import Moment from 'moment';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import bannerImage from '../../images/banner-1.png';
import GetStartedSection from './componets/GetStartedSection';
import * as businessActions from '../../reducers/businessReducer';

import '../../styles/home.css';

Moment.locale('en-GB');

class Home extends React.Component {
  render() {
    const { updateBusinessStore, businessType, pushToGetStarted } = this.props;
    console.log('businessType', businessType);
    return (
      <div>
        <div className="banner-section">
          <GetStartedSection
            updateBusinessStore={updateBusinessStore}
            businessType={businessType}
            pushToGetStarted={pushToGetStarted}
          />
          <img alt="banner" className="banner-image" src={bannerImage} />
        </div>
        <div className="section-1">
          <div className="why-section">
            <h2>Why list on book4?</h2>
            <div className="text-body">
              book4 can help ensure that your business doesn't miss the opportunity to make the most of time.
              enabling you to be in full control of your availability, pricing, policies and how you intreact with your customers.
            </div>
          </div>
          <div className="guarantee-section">
            <h2>Guarantee reveune</h2>
            <div className="text-body">
              book4 gives you full control by allowing you to take pre-paid deposits for bookings, as well as full service payments.
              This ensure reveune for when you encounter no-show bookings where you may have otherwise missed out on that potiental revenue.
            </div>
          </div>
        </div>
        <div className="section-headers">Straight forward payments</div>
        <div className="section-2">
          <div className="deposit-section">
            <h2>Ability to charge to deposits</h2>
            <div className="text-body-2">
              You will have the option to charge deposits for bookings, you will have full control over the amount set.
            </div>
          </div>
          <div className="low-fee-section">
            <h2>Low fee's</h2>
            <div className="text-body-2">
              There are no cost to sign-up or to use the book4 platform, although we do use Stripe as a payment gateway and Stripe do charge 1.4% + 20p per transaction. so example on a £10 booking 34p would be charged.
            </div>
          </div>
          <div className="payouts-section">
            <h2>Payouts</h2>
            <div className="text-body-2">
              Instant Payouts are available with supported banks accounts, but you can choose to receive them weekly or monthly in your Stripe account’s payout settings.
            </div>
          </div>
        </div>
        <div className="section-headers">Pricing</div>
        <div className="section-3">
          <div className="free-section">
            <h2>Free</h2>
            <div className="text-body-2">
              Free
              <Button
                variant="contained"
                color="primary"
                className="button-special"
                style={{
                  marginTop: '30px',
                  color: '#fff',
                  width: '100%',
                  height: '40px',
                  borderRadius: '5px',
                  boxShadow: '0 1px 4px 0 rgba(21, 27, 38, 0.2)',
                }}
                onClick={() => pushToGetStarted()}
                raised="true"
              >
                Get started
              </Button>
            </div>
          </div>
          <div className="basic-section">
            <h2>Basic</h2>
            <div className="text-body-2">
              Paid
              <Button
                variant="contained"
                color="primary"
                className="button-special"
                style={{
                  marginTop: '30px',
                  color: '#fff',
                  width: '100%',
                  height: '40px',
                  borderRadius: '5px',
                  boxShadow: '0 1px 4px 0 rgba(21, 27, 38, 0.2)',
                }}
                onClick={() => pushToGetStarted()}
                raised="true"
              >
                Get started
              </Button>
            </div>
          </div>
          <div className="executive-section">
            <h2>Executive</h2>
            <div className="text-body-2">
              Contact
              <Button
                variant="contained"
                color="secondary"
                className="button-special"
                style={{
                  marginTop: '30px',
                  color: '#fff',
                  width: '100%',
                  height: '40px',
                  borderRadius: '5px',
                  boxShadow: '0 1px 4px 0 rgba(21, 27, 38, 0.2)',
                }}
                // onClick={() => pushToGetStarted()}
                raised="true"
              >
                Contact Us
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changePage: () => push('/about-us'),
  pushToGetStarted: () => push('/getstarted'),
  updateBusinessStore: (data) => (businessActions.updateBusinessStore(data)),
}, dispatch);

export default connect(null, mapDispatchToProps)(Home);
