import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as burgerMenu } from 'redux-burger-menu';
import userReducer from './userReducer';
import currentUserReducer from '../../shared/reducers/currentUserReducer';
import businessReducer from './businessReducer';
import loginReducer from '../../shared/reducers/loginReducer';

export default combineReducers({
  router: routerReducer,
  burgerMenu,
  user: userReducer,
  business: businessReducer,
  currentUser: currentUserReducer,
  loginReducer,
});
