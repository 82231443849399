import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';

import '../styles/footer.css';

const year = moment().format('YYYY');

function footer() {
  return (
    <div className="footer">
      <div className="footerSections">

        <div className="aboutSection">
          <Typography variant="subtitle1">About</Typography>
          How book4 works
        </div>
        <div className="hostSection">
          <Typography variant="subtitle1">List</Typography>
          List your business
        </div>
      </div>
      <div className="copyrightNotice">
        {' '}
        &copy;
        {' '}
        {year}
        {' '}
        book4. All rights reserved.
      </div>
    </div>
  );
}

export default (footer);
