import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  withStyles,
  withMobileDialog,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import * as userActions from '../../FrontEnd/reducers/userReducer';
import * as currentUserActions from '../reducers/currentUserReducer';

// Material-UI

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  passwordResetLink: {
    fontWeight: 600,
    fontSize: '0.8em',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  errorText: {
    color: 'red !important',
    paddingTop: '20px',
  },
});

class LoginDialog extends React.Component {
  state = {
    type: '',
  };

  handleRequestClose = () => {
    const { toggleLoginDialogue } = this.props;
    toggleLoginDialogue();
  };

  login = () => {
    const { email, password } = this.state;
    const { login } = this.props;
    login(email, password);
  }

  handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      this.handleRequestClose(true);
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  wantsSignUp = () => {
    this.props.wantsSignUp();
    this.handleRequestClose();
  }

  wantsPasswordReset = () => {
    this.props.wantsPasswordReset();
    this.handleRequestClose();
  }

  render() {
    const {
      fullScreen,
      classes,
      toggleLoginDialogue,
      showLoginDialogue,
      isLoggedIn,
      wantsLogOut,
      loginError,
      tryingLogin,
    } = this.props;

    const {
      name,
    } = this.state;

    return (
      <div>
        {(isLoggedIn === true) ? (
          <>
            {/* <Link className={classes.link} to="/account"> */}
            <Button
              variant="contained"
              color="primary"
              className="button-special"
              style={{
                color: '#fff',
                width: '100px',
                height: '40px',
                borderRadius: '5px',
                boxShadow: '0 1px 4px 0 rgba(21, 27, 38, 0.2)',
                marginTop: '13px',
                marginRight: '5px',
              }}
              href="/account"
            >
              Account
            </Button>
            {/* </Link> */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => wantsLogOut()}
              className="button-special"
              style={{
                color: '#fff',
                width: '100px',
                height: '40px',
                borderRadius: '5px',
                boxShadow: '0 1px 4px 0 rgba(21, 27, 38, 0.2)',
                marginTop: '13px',
                marginLeft: '5px',
              }}
            >
              Log Out
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className="button-special"
            onClick={() => toggleLoginDialogue()}
            style={{
              color: '#fff',
              width: '100px',
              height: '40px',
              borderRadius: '5px',
              boxShadow: '0 1px 4px 0 rgba(21, 27, 38, 0.2)',
              marginTop: '13px',
            }}
          >
            Login
          </Button>
        )}
        <Dialog open={showLoginDialogue} onClose={this.handleRequestClose} fullScreen={fullScreen} style={{ zIndex: 100001 }}>
          <DialogTitle>Log In</DialogTitle>
          <DialogContent>
            <TextField
              id="email"
              label="Email"
              value={name}
              onChange={this.handleChange('email')}
              onKeyDown={this.handleKeyPress}
              type="email"
              autoCorrect="off"
              autoCapitalize="none"
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              id="password"
              label="Password"
              type="password"
              value={name}
              onChange={this.handleChange('password')}
              onKeyDown={this.handleKeyPress}
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <DialogActions>
              <Button
                disabled={tryingLogin}
                onClick={() => this.login()}
                variant="contained"
                color="primary"
                className="button-special"
                fullWidth
                style={{
                  color: '#fff',
                  height: '40px',
                  borderRadius: '5px',
                  boxShadow: '0 1px 4px 0 rgba(21, 27, 38, 0.2)',
                  marginTop: '13px',
                }}
              >
                Login
              </Button>

            </DialogActions>
            {loginError ? (
              <Typography
                className={classes.errorText}
                type="caption"
                gutterBottom
              >
                Invalid Login
              </Typography>
            ) : (
              <>
                <br />
                <br />
              </>
            )}
          </DialogContent>
          <DialogContent>
            <Typography
              className={classes.passwordResetLink}
              type="caption"
              gutterBottom
              onClick={() => this.wantsPasswordReset()}
            >
              Forgotten your password?
            </Typography>

          </DialogContent>
          <div style={{ height: '4px' }}>
            {tryingLogin && (
              <LinearProgress
                variant="indeterminate"
                value={100}
              />
            )}
          </div>
        </Dialog>
      </div>
    );
  }
}

LoginDialog.propTypes = {
  classes: PropTypes.shape().isRequired,
  fullScreen: PropTypes.bool.isRequired,
  toggleLoginDialogue: PropTypes.func.isRequired,
  showLoginDialogue: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  wantsLogOut: PropTypes.func.isRequired,
  tryingLogin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { showLoginDialogue, loginError, tryingLogin } = state.currentUser;
  return { showLoginDialogue, loginError, tryingLogin };
};

const mapDispatchToProps = (dispatch) => ({
  toggleLoginDialogue: () => dispatch(currentUserActions.toggleLoginDialogue()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withMobileDialog()(LoginDialog)));
