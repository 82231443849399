/* eslint-disable react/prefer-stateless-function */
import React from 'react';

class AllSetStep extends React.Component {
  render() {
    return (
      <div>
        All set, continue to your dashboard to get the final
        confgiurations setup to get your business live.
      </div>
    );
  }
}

export default (AllSetStep);
