import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import * as userActions from '../../../reducers/userReducer';

// eslint-disable-next-line react/prefer-stateless-function
class UserDetailsStep extends React.Component {
  render() {
    const { userDetails, updateUserCreationStore } = this.props;
    const {
      firstName, lastName, userPassword,
      userEmailaddress, userPhone,
      userConfirmPassword,
      passwordsMatch,
    } = userDetails;

    if (userPassword !== '' && userPassword === userConfirmPassword && !passwordsMatch) {
      updateUserCreationStore({ passwordsMatch: true });
    }
    if (userPassword !== '' && userPassword !== userConfirmPassword && passwordsMatch) {
      updateUserCreationStore({ passwordsMatch: false });
    }

    return (
      <div className="user-details-stepper-wrapper">
        <div className="user-details-stepper-block">
          These details will not be shown publicly, and
          will be used for loging in to access your account.
          <TextField
            id="first-name"
            variant="outlined"
            label="First name"
            value={firstName}
            fullWidth
            margin="normal"
            required
            onChange={(event) => updateUserCreationStore(
              { firstName: event.target.value },
            )}
          />
          <TextField
            id="last-name"
            label="Surname"
            value={lastName}
            onChange={(event) => updateUserCreationStore({ lastName: event.target.value })}
            margin="normal"
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            id="user-emailaddress"
            label="Email Address"
            value={userEmailaddress}
            onChange={(event) => updateUserCreationStore({ userEmailaddress: event.target.value })}
            margin="normal"
            variant="outlined"
            required
            fullWidth
          />
          <TextField
            id="user-password"
            label="Password"
            type="password"
            value={userPassword}
            onChange={(event) => updateUserCreationStore({ userPassword: event.target.value })}
            margin="normal"
            variant="outlined"
            required
            fullWidth
          />
          <TextField
            id="user-password"
            label="Confirm Password"
            type="password"
            value={userConfirmPassword}
            onChange={(event) => updateUserCreationStore({ userConfirmPassword: event.target.value })}
            margin="normal"
            variant="outlined"
            required
            fullWidth
          />
          <TextField
            id="user-phone"
            label="Phone number"
            value={userPhone}
            onChange={(event) => updateUserCreationStore({ userPhone: event.target.value })}
            margin="normal"
            variant="outlined"
            fullWidth
          />
        </div>
      </div>
    );
  }
}

UserDetailsStep.propTypes = {
  userDetails: PropTypes.shape().isRequired,
  updateUserCreationStore: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userDetails: state.user.userDetails,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateUserCreationStore: (data) => (userActions.updateUserCreationStore(data)),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsStep);
