export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_USER_TOKEN = 'UPDATE_USER_TOKEN';
export const TOGGLE_LOGIN_DIALOGUE = 'TOGGLE_LOGIN_DIALOGUE';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const IS_LOADING = 'IS_LOADING';
export const IS_MANAGER = 'IS_MANAGER';

export const UPDATED_GET_STARTED_STORE = 'UPDATED_GET_STARTED_STORE';

export const initialState = {
  currentUser: {},
  isLoggedIn: (localStorage.getItem('b4token') !== null),
  showLoginDialogue: false,
  loginError: false,
  isLoading: false,
  tryingLogin: false,
  isManager: false,
};

export function isManager(bool) {
  return { type: IS_MANAGER, bool };
}
export function updateCurrentUser(user) {
  return { type: UPDATE_CURRENT_USER, user };
}
export function updateGetStartedStore(data) {
  return { type: UPDATED_GET_STARTED_STORE, data };
}
export function updateUserToken(user) {
  return { type: UPDATE_USER_TOKEN, user };
}
export function userLoginFailed(error) {
  return { type: USER_LOGIN_FAILED, error };
}
export function toggleLoginDialogue() {
  return { type: TOGGLE_LOGIN_DIALOGUE };
}
export function isLoading(bool) {
  return { type: IS_LOADING, bool };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return { ...state, currentUser: action.user };
    case UPDATE_USER_TOKEN:
      localStorage.setItem('b4token', action.user.token);
      return { ...state, isLoggedIn: true, showLoginDialogue: false };
    case TOGGLE_LOGIN_DIALOGUE:
      return { ...state, showLoginDialogue: !state.showLoginDialogue };
    case IS_LOADING:
      return { ...state, isLoading: action.bool };
    case UPDATED_GET_STARTED_STORE:
      return { ...state, getStarted: { ...state.getStarted, ...action.data } };
    default:
      return state;
  }
};
