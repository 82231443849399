import React from 'react';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import BarberStock from '../../images/barberstock.jpg';
import BarberShopOverview from './barberShopWrapper';
import RestaurantOverview from './restaurantWrapper';

import * as actions from '../../reducers/businessReducer';


import '../../styles/business-overview.css';

class BusinessOverview extends React.Component {
  componentDidMount() {
    if (this.props.match.path === '/tbd/:urlSlug') {
      this.props.requestBusinessInformation(this.props.match.params.urlSlug);
    }
  }
  render() {
    const {
      business,
    } = this.props;
    if (!business) { return <div style={{ marginTop: '100px', marginLeft: '80px' }}>Loading...</div>; }

    const { businessType } = business;
    console.log(this.props);
    if (businessType === 'Barber Shop') {
      return <BarberShopOverview business={business} />
    }
    if (businessType === 'Restaurant') {
      return <RestaurantOverview business={business} />
    }
  }
}


const mapStateToProps = (state) => ({
  business: state.business.business,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    requestBusinessInformation: (user) => actions.requestBusinessRetrieval(user),
  },
  dispatch,
);
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(BusinessOverview));
