// @flow weak

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { action as toggleMenu } from 'redux-burger-menu';
import Responsive from 'react-responsive';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import store from '../../store';
import Logo from '../images/mainlogo.png';
import LoginDialog from '../../shared/components/LoginDialog';

// Material-ui

const Mobile = ({ children }) => <Responsive maxWidth={768} children={children} />;

const styles = {
  root: {
    width: '100%',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    color: '#000',
    text: {
      padding: '6px 16px',
    },
  },
  flex: {
    // flex: 1,
    marginLeft: '80px', // Title margin
    marginRight: 'auto',
    align: 'left',
    textDecoration: 'none',
    color: '#000',
  },
  image: {
    height: 40,
  },
  imageSmall: {
    height: 30,
  },
  button: {
    color: '#000',
  },
  link: {
    textDecoration: 'none',
    color: '#000',
    paddingRight: '5px',
    paddingLeft: '5px',
  },
};

const managerDashboardUrl = 'https://manager.book4.com/';

function ButtonAppBar(props) {
  const { classes, numberOfItemsInBasket } = props;
  const menuBarContents = (
    <Toolbar disableGutters>
      <Link to="/" className={classes.flex}>
        <Responsive minDeviceWidth={400}>
          {(matches) => {
            if (matches) {
              return (
                <img src={Logo} alt="" className={classes.image} />
              );
            }
            return (
              <img src={Logo} alt="" className={classes.imageSmall} />
            );
          }}
        </Responsive>
      </Link>
      <Mobile>
        {(isMobile) => {
          if (isMobile) {
            return (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >
                <IconButton onClick={() => store.dispatch(toggleMenu(true))} color="primary" className={classes.button} aria-label="Menu" classes={[]}>
                  <MenuIcon />
                </IconButton>

              </div>
            );
          }

          // DESKTOP
          return (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <div style={{ paddingRight: '40px', paddingBottom: '10px' }}>
                <LoginDialog
                  onFinish={(data) => props.login(data)}
                  isLoggedIn={props.isLoggedIn}
                  login={(email, password) => props.login(email, password)}
                  wantsLogOut={() => props.toggleLogin()}
                />
              </div>
              {/* {(props.isLoggedIn) && (
                <div>
                  <Link className={classes.link} to="/account">
                    <Button variant="contained" color="primary">
                      Account
                    </Button>
                  </Link>
                  <Button style={{ marginRight: '20px' }} variant="contained" color="primary" onClick={() => props.logout()}>
                    Logout
                  </Button>
                </div>
              )} */}
            </div>
          );
        }}
      </Mobile>
    </Toolbar>
  );

  return (
    <div className={classes.root}>
      <Responsive minDeviceWidth={992}>
        {(matches) => {
          if (matches) {
            return (
              <AppBar style={{ backgroundColor: '#fff', zIndex: 100001 }} position="fixed">
                {menuBarContents}
              </AppBar>
            );
          }
          return (
            <AppBar style={{ backgroundColor: '#fff' }} position="static">
              {menuBarContents}
            </AppBar>
          );
        }}
      </Responsive>
    </div>
  );
}

ButtonAppBar.propTypes = {
  classes: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(
  mapStateToProps,
)(withStyles(styles)(ButtonAppBar));
