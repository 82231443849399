/* eslint-disable no-constant-condition */
import { takeEvery, call, put } from 'redux-saga/effects';
import {
  loginUserServices,
} from '../services/loginService';
import * as userActions from '../reducers/currentUserReducer';
import * as actions from '../reducers/loginReducer';

function* loginUserSaga(userAction) {
  //   yield put(userActions.isLoading(true));
  try {
    const usersResponse = yield call(loginUserServices, userAction.reqBody);
    if (!usersResponse.error) {
      yield put(userActions.isLoading(false));
      const tokenDataEncoded = usersResponse.token.split('.')[1];
      const currentUser = JSON.parse(window.atob(tokenDataEncoded));
      // if (currentUser.adminCompanies.length > 0) {
      yield put(actions.updateUserToken(usersResponse));
      yield put(actions.updateCurrentUser(currentUser));
      // window.location.reload();
      // } else {
      // //   yield put(actions.updateUserError('User is not authorised'));
      // //   yield put(actions.openAccessDeniedDialog(true));
      // }
    } else {
      yield put(userActions.isLoading(false));
      // yield put(actions.updateUserError('Invalid email or password. Please check both and try again.'));
    }
  } catch (error) {
    console.log('Login error', error);
  }
}

export function* loginUserWatcher() {
  yield takeEvery(actions.USER_LOGGED_IN, loginUserSaga);
}
