import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import 'whatwg-fetch';

// Material-UI
import { ThemeProvider } from '@material-ui/core/styles';
import ScrollToTop from './shared/components/ScrollToTop';
import theme from './theme';

// Fonts & Icons
import './styles/index.css';
import './styles/overrides.css';

// Components and Redux Store
import store, { history } from './store';
import rootSaga from './FrontEnd/sagas/rootSaga';
import App from './FrontEnd/containers/app';
import ManagementApp from './BackEnd/containers/app';

store.runSaga(rootSaga);

const target = document.querySelector('#root');
const storeState = store.getState();
const { isManager } = storeState.currentUser;
render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <ScrollToTop>

          {isManager
            ? <ManagementApp />
            : <App />}
        </ScrollToTop>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  target,
);
