const token = localStorage.getItem('b4Token');

export async function getBusinessService(urlSlug) {
    console.log('SER: ', urlSlug);
    console.log('process: ', process);
    const url = `${process.env.REACT_APP_BOOK4_API_URL}/api/business/${urlSlug.business}`;
    console.log('url: ', url);
    const apiCall = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    console.log('BEFORE CALL');
    const response = await apiCall.json();
    console.log('AFTER CALLL:', response);

    return response.data;
}
