// React & Routing
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import '../../styles/app.css';

// Components
import Dashboard from '../dashboard';
import DaySchedule from '../dayschedule';
import AppBar from '../../components/AppBar';
import Footer from '../../../shared/components/Footer';
import * as loginActions from '../../../shared/reducers/loginReducer';
import * as currentUserActions from '../../../shared/reducers/currentUserReducer';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginIsOpen: false,
    };
  }

  toggleLogin = () => {
    const {
      isLoggedIn, updateCurrentUser, pushHome, location,
    } = this.props;

    if (isLoggedIn) {
      if (location.pathname !== '/') {
        pushHome();
      }
      localStorage.removeItem('b4token');
      updateCurrentUser();
      window.location.reload();
    } else {
      this.setState({
        loginIsOpen: true,
      });
    }
  };

  login = async (email, password, token) => {
    const userRequest = {
      username: email,
      password,
    };
    const { loginUser } = this.props;
    loginUser(userRequest);
  };

  resetPassword = (data, callback) => {
    const { email } = data;
    const { issuePasswordToken } = this.props;

    issuePasswordToken(email);
  };

  logout() {
    localStorage.removeItem('b4token');
    const { updateCurrentUser } = this.props;
    updateCurrentUser({});
    this.setState({
      currentUser: null,
    });
  }

  render() {
    const { isLoggedIn } = this.props;
    const { passwordResetIsOpen } = this.state;
    return (
      <div
        style={{
          position: 'relative',
          height: '100%',
          minHeight: '100vh',
          overflowY: 'auto',
          margin: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AppBar
          logout={() => this.logout()}
          isLoggedIn={isLoggedIn}
          toggleLogin={() => this.toggleLogin()}
          login={(email, password) => this.login(email, password)}
          resetPassword={(data, callback) => this.resetPassword(data, callback)}
          wantsPasswordReset={(wantsPasswordReset) => {
            this.setState({ passwordResetIsOpen: wantsPasswordReset });
          }}
          passwordResetIsOpen={passwordResetIsOpen}
        />
        <main
          style={{
            flexGrow: 1,
            flexShrink: 0,
          }}
        >
          <Switch>
            <Route exact path="/" component={Dashboard} />
            {/* <Route exact path="/tbd/:urlSlug" component={BusinessOverview} />
            <Route exact path="/account" component={AccountPage} />
            <Route exact path="/getstarted" component={BusinessSignUp} /> */}
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/schedule" component={DaySchedule} />

            {/* <Route exact path="/verification-success" component={EmailVerfied} />
            <Route path="/change-password/:token" component={ChangePassword} />
            <Route
              path="/login/:confirmation"
              render={(props) => <Login login={(email, password, token) =>
                this.login(email, password, token)} logOut={this.logOut} {...props} />}
            /> */}
            {/* <Route component={_404} /> */}
          </Switch>
        </main>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoggedIn: state.currentUser.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    pushHome: () => push('/'),
    loginUser: (user) => (loginActions.loginUser(user)),
    updateCurrentUser: (user) => dispatch(currentUserActions.updateCurrentUser(user)),

    // signUp: (user) => currentUserActions.signUpUser(user),
    // changeCurrentUser: (user) => currentUserActions.changeCurrentUser(user),
    // issuePasswordToken: (user) => currentUserActions.issuePasswordToken(user),
  },
  dispatch,
);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(App));
