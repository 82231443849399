import { fork, all } from 'redux-saga/effects';

import {
  getBusinessWatcher,
} from './businessSaga';
import {
  loginUserWatcher,
} from '../../shared/sagas/loginSaga';
import {
  userAccountDataRequestedWatcher,
} from './userSaga';

export default function* root() {
  yield all([
    fork(getBusinessWatcher),
    fork(loginUserWatcher),
    fork(loginUserWatcher),
    fork(userAccountDataRequestedWatcher),

  ]);
}
