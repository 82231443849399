import { createMuiTheme } from '@material-ui/core/styles';
import store, { history } from './store';

let theme;
const storeState = store.getState();
const isManager = storeState.currentUser.isManager;


if (isManager) {
  theme = createMuiTheme({
    palette: {
      primary: {
        main: '#018DF2',
        // main: '#445AF7',
        mainGradient: 'linear-gradient(145deg, rgba(223, 93, 102, 1) 60%, rgba(255, 163, 169, 1) 100%)',
        contrastText: '#fff',
      },
      secondary: {
        main: '#6699FF',
        contrastText: '#fff',
      },
      accent: {
        main: '#e0e0e0',
        contrastText: '#000',
      },
      error: {
        main: '#B00020',
        contrastText: '#fff',
      },
      textSecondary: {
        main: '#7889FF',
        contrastText: '#fff',
      },
      background: {
        default: '#fff',
      },
    },
    typography: {
      fontFamily: 'Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important',
    },
  });
} else {
  theme = createMuiTheme({
    palette: {
      primary: {
        main: '#DF5D66',
        mainGradient: 'linear-gradient(145deg, rgba(223, 93, 102, 1) 60%, rgba(255, 163, 169, 1) 100%)',
        contrastText: '#fff',
      },
      secondary: {
        main: '#6699FF',
        contrastText: '#fff',
      },
      accent: {
        main: '#e0e0e0',
        contrastText: '#000',
      },
      error: {
        main: '#B00020',
        contrastText: '#fff',
      },
      textSecondary: {
        main: '#7889FF',
        contrastText: '#fff',
      },
      background: {
        default: '#fff',
      },
    },
    typography: {
      fontFamily: 'Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important',
    },
  });

}
export default theme;
