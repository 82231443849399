export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_USER_TOKEN = 'UPDATE_USER_TOKEN';

export const initialState = {
  isLoggedIn: (localStorage.getItem('b4token') !== null),
  currentUser: {},
};

// Login...
export function loginUser(reqBody) {
  return { type: USER_LOGGED_IN, reqBody };
}
export function updateCurrentUser(user) {
  return { type: UPDATE_CURRENT_USER, user };
}
export function updateUserToken(user) {
  return { type: UPDATE_USER_TOKEN, user };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return { ...state, currentUser: action.user };
    case UPDATE_USER_TOKEN:
      localStorage.setItem('b4token', action.user.token);
      return { ...state };
    default:
      return state;
  }
};
