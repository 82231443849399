import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as userActions from '../../reducers/userReducer';

import '../../styles/account.css';

class AccountPage extends React.Component {
  componentDidMount = () => {
    this.props.userAccountDataRequested();
  }

  render() {
    const {
      classes,
      isLoggedIn,
      userAccountData,
      doUpdateUserPassword,
      doTogglePasswordDialog,
    } = this.props;

    return (
      <div className="account-container">
        {isLoggedIn ? (
          <div className="account-block-1">
            <Typography variant="h5">
              Account Summary
            </Typography>
            <div className="account-block-2">
              <div className="account-details-1">
                First Name
                <TextField
                  id="firstName"
                  value={userAccountData.firstName}
                  // onChange={this.handleChange('firstName', currentUserAccountData)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                Surname
                <TextField
                  id="surname"
                  value={userAccountData.lastName}
                  // onChange={this.handleChange('firstName', currentUserAccountData)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                Email Address
                <TextField
                  id="emailAddress"
                  value={userAccountData.emailaddress}
                  // onChange={this.handleChange('firstName', currentUserAccountData)}
                  margin="normal"
                  variant="outlined"
                  disabled
                  fullWidth
                />
                <Typography gutterBottom variant="subtitle" color="textSecondary">
                  Change
                </Typography>
                <br />
                <Typography variant="subtitle">
                  Password
                </Typography>
                <TextField
                  id="password"
                  value="********"
                  // onChange={this.handleChange('firstName', currentUserAccountData)}
                  margin="normal"
                  variant="outlined"
                  disabled
                  fullWidth
                />
                <Typography gutterBottom variant="subtitle" color="textSecondary">
                  Change
                </Typography>
              </div>
              <Button variant="contained" color="primary">Edit</Button>
            </div>
          </div>
        ) : (
          <div>
            <Typography variant="h6">
              Please Log In
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.currentUser.isLoggedIn,
  userAccountData: state.user.account,
  saveStatus: state.user.saveStatus,
  loadingUserAccount: state.user.loadingUserAccount,
});

const mapDispatchToProps = (dispatch) => ({
  userAccountDataRequested: () => dispatch(userActions.userAccountDataRequested()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountPage);
