export const USER_ACCOUNT_DATA_REQUESTED = 'USER_ACCOUNT_DATA_REQUESTED';
export const USER_ACCOUNT_DATA_RETRIEVED = 'USER_ACCOUNT_DATA_RETRIEVED';
export const USER_ACCOUNT_DATA_REQUEST_FAILURE = 'USER_ACCOUNT_DATA_REQUEST_FAILURE';

export const CHANGE_CURRENT_USER = 'CHANGE_CURRENT_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const RESET_PASSWORD_WITH_TOKEN_SUCCESS = 'RESET_PASSWORD_WITH_TOKEN_SUCCESS';
export const UPDATE_USER_ACCOUNT_DATA = 'UPDATE_USER_ACCOUNT_DATA';
export const USER_ACCOUNT_DATA_UPDATED = 'USER_ACCOUNT_DATA_UPDATED';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const USER_PASSWORD_UPDATED = 'USER_PASSWORD_UPDATED';
export const USER_ACCOUNT_DATA_UPDATE_FAILED = 'USER_ACCOUNT_DATA_UPDATE_FAILED';
export const TOGGLE_CHANGE_PASSWORD_DIALOG = 'TOGGLE_CHANGE_PASSWORD_DIALOG';
export const USER_PASSWORD_UPDATE_FAILED = 'USER_PASSWORD_UPDATE_FAILED';
export const USER_PASSWORD_TOKEN_ISSUED = 'USER_PASSWORD_TOKEN_ISSUED';
export const RESET_PASSWORD_WITH_TOKEN_FAILED = 'RESET_PASSWORD_WITH_TOKEN_FAILED';
export const USER_PASSWORD_RESET = 'USER_PASSWORD_RESET';
export const USER_SIGNUP_FAILED = 'USER_SIGNUP_FAILED';
export const GET_USER_PREFILL_DATA = 'GET_USER_PREFILL_DATA';
export const CONFIRM_INVITE = 'CONFIRM_INVITE';
export const UPDATED_USER_DETAILS_STORE = 'UPDATED_USER_DETAILS_STORE';

const initialState = {
  currentUser: {},
  account: {
    firstName: '',
    lastName: '',
    emailaddress: '',
    phone: '',
    joinDate: '',
  },
  userDetails: {
    firstName: '',
    lastName: '',
    emailaddress: '',
    userPassword: '',
    userConfirmPassword: '',
    phone: '',
    passwordsMatch: false,
  },
  saveStatus: '',
  tryingUserSignUp: false,
  tryingPasswordChange: false,
  tryingPasswordReset: false,
  passwordChangeError: false,
  passwordChangeSuccess: false,
  passwordChangeErrorMessage: '',
  passwordChangeErrorMessages: '',
  passwordResetSuccess: false,
  passwordResetError: false,
  passwordResetErrorMessage: '',
  passwordResetErrorMessages: '',
  passwordTokenIssued: false,
  userSignUpError: false,
  userSignUpSuccess: false,
  userSignUpErrorMessage: '',
  userSignUpErrorMessages: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_ACCOUNT_DATA_REQUESTED:
      return { ...state, saveStatus: '', loadingUserAccount: true };
    case USER_ACCOUNT_DATA_RETRIEVED:
      return { ...state, account: action.userAccountData, loadingUserAccount: false };
    case SIGNUP_USER_SUCCESS:
      return {
        ...state,
        tryingUserSignUp: true,
        userSignUpSuccess: true,
      };
    case RESET_PASSWORD_WITH_TOKEN_SUCCESS:
      return {
        ...state,
        tryingPasswordReset: true,
        passwordResetSuccess: true,
      };
    case USER_SIGNUP_FAILED:
      return {
        ...state,
        tryingUserSignUp: false,
        userSignUpError: true,
        userSignUpErrorMessage: action.error,
        userSignUpErrorMessages: action.errors,
      };
    case UPDATE_USER_ACCOUNT_DATA:
      return { ...state, saveStatus: 'Saving...', loadingUserAccount: true };
    case USER_ACCOUNT_DATA_UPDATED:
      return {
        ...state, account: action.updatedUserAccountData, saveStatus: 'Saved', loadingUserAccount: false,
      };
    case USER_ACCOUNT_DATA_UPDATE_FAILED:
      return { ...state, saveStatus: 'update failed', loadingUserAccount: false };
    case TOGGLE_CHANGE_PASSWORD_DIALOG:
      return {
        ...state,
        tryingPasswordChange: false,
        passwordChangeError: false,
        passwordChangeSuccess: false,
        passwordChangeErrorMessage: '',
      };
    case UPDATE_USER_PASSWORD:
      return { ...state, tryingPasswordChange: true };
    case USER_PASSWORD_UPDATED:
      return {
        ...state,
        tryingPasswordChange: false,
        passwordChangeSuccess: true,
      };
    case RESET_PASSWORD_WITH_TOKEN_FAILED:
      return {
        ...state,
        passwordResetError: true,
        passwordResetErrorMessage: action.error,
        passwordResetErrorMessages: action.errors,
      };
    case USER_PASSWORD_UPDATE_FAILED:
      return {
        ...state,
        tryingPasswordChange: false,
        passwordChangeError: true,
        passwordChangeErrorMessage: action.error,
        passwordChangeErrorMessages: action.errors,
      };
    case USER_PASSWORD_TOKEN_ISSUED:
      return {
        ...state,
        passwordTokenIssued: true,
      };
    case SIGNUP_USER:
      return {
        ...state,
        tryingUserSignUp: true,
        userSignUpError: false,
        userSignUpSuccess: false,
        userSignUpErrorMessage: '',
      };
    case UPDATED_USER_DETAILS_STORE:
      return { ...state, userDetails: { ...state.userDetails, ...action.data } };
    default:
      return state;
  }
};

export const userAccountDataRequested = () => ({
  type: USER_ACCOUNT_DATA_REQUESTED,
});
export const userAccountDataRetrieved = (userAccountData) => ({
  type: USER_ACCOUNT_DATA_RETRIEVED,
  userAccountData,
});
export const userAccountDataRequestFailed = () => ({
  type: USER_ACCOUNT_DATA_REQUEST_FAILURE,
});

export function issuePasswordToken(email) {
  return {
    type: USER_PASSWORD_TOKEN_ISSUED,
    email,
  };
}
export function getPrefillData(token) {
  return {
    type: GET_USER_PREFILL_DATA,
    token,
  };
}
export function confirmInvite(user) {
  return {
    type: CONFIRM_INVITE,
    user,
  };
}
export function resetPasswordWithToken(passwordResetToken, newPassword) {
  return {
    type: USER_PASSWORD_RESET,
    passwordResetToken,
    newPassword,
  };
}
export function signUpUser(user) {
  return {
    type: SIGNUP_USER,
    user,
  };
}
export function signUpUserSuccess() {
  return {
    type: SIGNUP_USER_SUCCESS,
  };
}
export function resetPasswordWithTokenSuccess() {
  return {
    type: RESET_PASSWORD_WITH_TOKEN_SUCCESS,
  };
}
export const changeCurrentUser = (user) => {
  let isLoggedIn = false;
  if (user !== null) {
    isLoggedIn = true;
  }

  return {
    type: CHANGE_CURRENT_USER,
    currentUser: user,
    isLoggedIn,
  };
};
export const failUserSignUp = (error, errors) => ({
  type: USER_SIGNUP_FAILED,
  error,
  errors,
});
export const failResetPasswordWithToken = (error, errors) => ({
  type: RESET_PASSWORD_WITH_TOKEN_FAILED,
  error,
  errors,
});
export const userAccountDataUpdated = (updatedUserAccountData) => (
  {
    type: USER_ACCOUNT_DATA_UPDATED,
    updatedUserAccountData,
  });

export const updateUserAccountData = (userAccountData) => ({
  type: UPDATE_USER_ACCOUNT_DATA,
  userAccountData,
});
export const updateUserPassword = (userPasswordData) => ({
  type: UPDATE_USER_PASSWORD,
  userPasswordData,
});
export const userPasswordUpdated = () => ({
  type: USER_PASSWORD_UPDATED,
});
export const userAccountDataUpdateFailed = (error) => ({
  type: USER_ACCOUNT_DATA_UPDATE_FAILED,
  error,
});
export const userPasswordUpdateFailed = (error, errors) => ({
  type: USER_PASSWORD_UPDATE_FAILED,
  error,
  errors,
});
export const togglePasswordDialog = () => ({
  type: TOGGLE_CHANGE_PASSWORD_DIALOG,
});
export const updateUserCreationStore = (data) => (
  { type: UPDATED_USER_DETAILS_STORE, data }
);
