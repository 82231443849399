/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as currentUserActions from '../../../shared/reducers/currentUserReducer';

import Block1 from './Block1';
import Block2 from './Block2';
import Block3 from './Block3';
import '../../styles/dashboard.css';

class Dashboard extends React.Component {
  render() {
    return (
      <div className="dashboard-main">
        <div className="dashboard-main-title">
          Dashboard
        </div>
        <div className="dashboard-blocks">
          <Block1 />
          <Block2 />
          <Block3 />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  push: () => push('/about-us'),
  updateGetStartedStore: (data) => (currentUserActions.updateGetStartedStore(data)),
}, dispatch);

export default connect(null, mapDispatchToProps)(Dashboard);
