/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as currentUserActions from '../../../shared/reducers/currentUserReducer';

class BlankTemplate extends React.Component {
  render() {
    return (
      <div className="dashboard-block-2">
        <div className="dashboard-block-1-title">
          Quick summary
        </div>
        <div className="dashboard-summary-card-wrapper">
          <div className="dashboard-summary-card-left">
            <div className="dashboard-block-1-body">
              Past 30 Days
            </div>
            <div className="dashboard-summary-card-1">
              <div className="dashboard-summary-card-contents">
                Appointments: 184
              </div>
            </div>
            <div className="dashboard-summary-card-2">
              <div className="dashboard-summary-card-contents">
                Total: $2,760
              </div>
            </div>
            <div className="dashboard-summary-card-4">
              <div className="dashboard-summary-card-contents">
                Cancellations: 0
              </div>
            </div>
            {/* <div className="dashboard-summary-card-3">
              <div className="dashboard-summary-card-contents">
                Upcoming: 97
              </div>
            </div> */}
          </div>
          <div className="dashboard-summary-card-right">
            <div className="dashboard-block-1-body">
              Next 30 Days
            </div>
            <div className="dashboard-summary-card-1">
              <div className="dashboard-summary-card-contents">
                Appointments: 211
              </div>
            </div>
            <div className="dashboard-summary-card-2">
              <div className="dashboard-summary-card-contents">
                Total: $3,190
              </div>
            </div>
            <div className="dashboard-summary-card-4">
              <div className="dashboard-summary-card-contents">
                Cancellations: 0
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  push: () => push('/about-us'),
  updateGetStartedStore: (data) => (currentUserActions.updateGetStartedStore(data)),
}, dispatch);

export default connect(null, mapDispatchToProps)(BlankTemplate);
