/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

class CalendarSlot extends React.Component {
  render() {
    const {
      reservations, holidays, memberships, subscriptions, facilityId, hour, key, calendarUsage,
    } = this.props;

    // const endOfDay = moment.utc(calendarUsage.close, 'HH:mm');
    const hourFormat = moment.utc(hour.hour, 'HH:mm');
    const formattedHour = hour.hour.format('HH:mm');
    const formattedHourWithSeconds = hour.hour.format('HH:mm:ss');
    const className = formattedHour.toString().includes('00') ? 'onHour' : 'hour';
    let slotView = (<div key={key} className={className} />);


    return slotView;
  }
}


function mapStateToProps(state) {

  return {
  };
}


export default connect(mapStateToProps, null)(CalendarSlot);
