import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 10,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleSelect(props) {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        fullWidth
      >
        <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
          Business Type *
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={props.businessType}
          onChange={(event) => props.updateBusinessStore(
            { businessType: event.target.value },
          )}
          labelWidth={labelWidth}
          style={{ width: '100%' }}
        >
          <MenuItem value="Restaurant">Restaurant</MenuItem>
          <MenuItem value="Barber shop">Barber shop</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
