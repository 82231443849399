export const BUSINESS_INFO_REQUESTED = 'BUSINESS_INFO_REQUESTED';
export const BUSINESS_INFO_RETRIVED = 'BUSINESS_INFO_RETRIVED';

export const UPDATED_BUSINESS_STORE = 'UPDATED_BUSINESS_STORE';

const initialState = {
  business: {
    businessName: null,
    businessType: null,
    businessEmail: null,
    addressLine1: null,
    addressLine2: null,
    businessCity: null,
    postcode: null,
    country: null,
    phone: null,
  },
};
export function requestBusinessRetrieval(business) {
  console.log('RED');
  return {
    type: BUSINESS_INFO_REQUESTED,
    business,
  };
}
export function succeededBusinessRetrieval(business) {
  return {
    type: BUSINESS_INFO_RETRIVED,
    business,
  };
}

export const updateBusinessStore = (data) => (
  { type: UPDATED_BUSINESS_STORE, data }
);

export default (state = initialState, action) => {
  switch (action.type) {
    case BUSINESS_INFO_RETRIVED:
      return { ...state, business: action.business };
    case UPDATED_BUSINESS_STORE:
      return { ...state, business: { ...state.business, ...action.data } };
    default:
      return state;
  }
};
