import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import BusinessSelect from './BusinessSelect';

export default function GetStartedSection(props) {
  const { updateBusinessStore, businessType, pushToGetStarted } = props;
  return (
    <div className="get-started-section">
      <h1>
        Get started
      </h1>
      Tell us about your business
      <form className="get-started-form" noValidate autoComplete="off">
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="Business Name"
          fullWidth
          onChange={(event) => updateBusinessStore(
            { businessName: event.target.value },
          )}
        />
        <BusinessSelect
          businessType={businessType}
          updateBusinessStore={updateBusinessStore}
        />
        <Button
          variant="contained"
          color="primary"
          className="button-special"
          style={{
            marginTop: '30px',
            color: '#fff',
            width: '100%',
            height: '40px',
            borderRadius: '5px',
            boxShadow: '0 1px 4px 0 rgba(21, 27, 38, 0.2)',
          }}
          onClick={() => pushToGetStarted()}
          raised="true"
        >
          Get started
        </Button>
      </form>
      <div className="tell-us-more">
        your business type not listed? tell us more.
      </div>
    </div>
  );
}

GetStartedSection.propTypes = {
  businessType: PropTypes.shape().isRequired,
  updateBusinessStore: PropTypes.func.isRequired,
  pushToGetStarted: PropTypes.func.isRequired,
};
