import React from 'react';
import Button from '@material-ui/core/Button';
import BarberStock from '../../images/barberstock.jpg';

import '../../styles/business-overview.css';

class BarberShopOverview extends React.Component {

  render() {
    const {
      business,
    } = this.props;
    if (!business) { return <div style={{ marginTop: '100px', marginLeft: '80px' }}>Loading...</div>; }

    const {
      name, businessType, businessEmail, addressLine1,
      addressLine2, city, postcode, country,
      phone
    } = business;
    return (
      <div style={{ marginTop: '100px', marginLeft: '80px' }}>
        <h1> {name}</h1>
        <div>
          {businessType}
        </div>
        <div className="business-wrapper">
          <div className="booking-section">
            Pre-pay £15
            <Button
              variant="contained"
              color="inherit"
              style={{
                marginTop: '14px',
                color: '#fff',
                width: '100%',
                height: '40px',
                borderRadius: '5px',
                backgroundColor: '#df5d66',
                boxShadow: '0 1px 4px 0 rgba(21, 27, 38, 0.2)',
              }}
              onClick={() => console.log('CLICKED')}
              raised="true"
            >
              Book Now
            </Button>
          </div>
          <div className="business-section">
            <img src={BarberStock} alt="" className="business-image" />
            <div className="business-information">Information.</div>
          </div>
        </div>
      </div>
    );

  }
}

export default (BarberShopOverview);
