const token = localStorage.getItem('b4token');

// export async function confirmInviteService(user) {
//     const url = '/api/users/confirmInvite';
//     const body = { user };
//     const response = httpHelpers.unauthenticatedPostRequest(url, body);
//     return response;
// }

// export async function signUpUserService(user) {
//     const url = '/api/users/signup';
//     const response = httpHelpers.unauthenticatedPostRequest(url, user);
//     return response;
// }

// export async function issuePasswordTokenService(email) {
//     const body = { email };
//     const url = '/api/users/generatePasswordToken';
//     const response = httpHelpers.unauthenticatedPostRequest(url, body);
//     return response;
// }

// export async function resetPasswordWithTokenService(passwordResetToken, newPassword) {
//     const body = { passwordResetToken, newPassword };
//     const url = '/api/users/resetPasswordWithtoken';
//     const response = httpHelpers.unauthenticatedPostRequest(url, body);
//     return response;
// }
export async function userAccountDataRequestedService() {
    console.log('service');
    const url = `${process.env.REACT_APP_BOOK4_API_URL}/api/users/account/info`;
    const apiCall = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const response = await apiCall.json();
    console.log('response', response);

    return response.data;
}


// export async function updateUserAccountDataService(userAccountData) {
//     const url = '/api/users/account/update';
//     const body = { ...userAccountData };
//     const response = httpHelpers.authenticatedPostRequest(url, body);
//     return response;
// }

// export async function updateUserPasswordService(userPasswordData) {
//     const body = { ...userPasswordData };
//     const url = '/api/users/updatePassword';
//     const response = httpHelpers.authenticatedPostRequest(url, body);
//     return response;
// }
