import {
    takeEvery, call, put,
} from 'redux-saga/effects';
import {
    getBusinessService,
} from '../services/businessService';
import * as actions from '../reducers/businessReducer';

// GET DATA
function* getBusinessSaga(urlSlug) {
    try {
        const businessResponse = yield call(getBusinessService, urlSlug);
        if (!businessResponse.error) {
            yield put(actions.succeededBusinessRetrieval(businessResponse));
        }
    } catch (error) {
        console.log('getBusinessSaga', error);
    }
}

// Watchers
export function* getBusinessWatcher() {
    yield takeEvery(actions.BUSINESS_INFO_REQUESTED, getBusinessSaga);
}