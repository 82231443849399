import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import GetStartedStepper from './components/GetStartedStepper';

import '../../styles/get-started.css';

// eslint-disable-next-line react/prefer-stateless-function
class BusinessSignUp extends React.Component {
  render() {
    return (
      <div className="get-started-main">
        <div className="get-started-block-1">
          <div className="get-started-title">
            <Typography variant="h5">
              Get Started
            </Typography>
          </div>
          <div className="get-started-stepper">
            <GetStartedStepper />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business.business,
});

export default connect(mapStateToProps, null)(BusinessSignUp);
