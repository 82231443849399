/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as currentUserActions from '../../../shared/reducers/currentUserReducer';

class BlankTemplate extends React.Component {
  render() {
    const { gotoSchedule } = this.props;
    return (
      <div className="dashboard-block-1">
        <div className="dashboard-block-1-title">
          Hi, Ryan
        </div>
        <div className="dashboard-block-1-body">
          You have 6 appointments left today
        </div>
        <div className="dashboard-block-1-body-2">
          Up next
        </div>
        <div className="dashboard-upnext-card">
          <div className="dashboard-upnext-card-left">
            <div className="dashboard-upnext-card-image">J</div>
          </div>
          <div className="dashboard-upnext-card-right">
            <div className="dashboard-upnext-card-title">
              Hair cut and Shave
            </div>
            <div className="dashboard-upnext-card-desctiption">
              John Wick at 12:00 - 12:30
            </div>
            <div className="dashboard-upnext-card-sub-desctiption">
              £25 paid in full
            </div>
          </div>
        </div>
        <div className="dashboard-upnext-card">
          <div className="dashboard-upnext-card-left">
            <div className="dashboard-upnext-card-image">L</div>
          </div>
          <div className="dashboard-upnext-card-right">
            <div className="dashboard-upnext-card-title">
              Hair cut
            </div>
            <div className="dashboard-upnext-card-desctiption">
              Liam Neeson at 12:30 - 13:00
            </div>
            <div className="dashboard-upnext-card-sub-desctiption">
              £15 paid in full
            </div>
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          margin="normal"
          fullWidth
          style={{
            color: '#fff',
            height: '40px',
            borderRadius: '5px',
            boxShadow: '0 1px 4px 0 rgba(21, 27, 38, 0.2)',
            marginTop: '13px',
          }}
          onClick={() => gotoSchedule()}
        >
          View Today's Schedule
        </Button>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  gotoSchedule: () => push('/schedule'),
  updateGetStartedStore: (data) => (currentUserActions.updateGetStartedStore(data)),
}, dispatch);

export default connect(null, mapDispatchToProps)(BlankTemplate);
