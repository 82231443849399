import {
  takeEvery, call, put,
} from 'redux-saga/effects';
import {
  userAccountDataRequestedService,
  // signUpUserService,
  // updateUserAccountDataService,
  // updateUserPasswordService,
  // resetPasswordWithTokenService,
  // retrieveUserPrefillDataService,
} from '../services/userServices';
import * as actions from '../reducers/userReducer';

// GET USERS


// function* issuePasswordTokenSaga(action) {
//   const { email } = action;
//   try {
//     const userResponse = yield call(issuePasswordTokenService, email);
//     if (!userResponse.error) {
//       // yield put(actions.updateCurrentUser(currentUser));
//     }
//   } catch (err) {
//     console.log('issuePasswordTokenSaga', err);
//   }
// }

// function* resetPasswordWithTokenSaga(action) {
//   const { passwordResetToken, newPassword } = action;
//   try {
//     const userResponse = yield call(resetPasswordWithTokenService, passwordResetToken, newPassword);
//     if (!userResponse.error && !userResponse.errors) {
//       yield put(actions.resetPasswordWithTokenSuccess());
//     } else {
//       yield put(actions.failResetPasswordWithToken(userResponse.error, userResponse.errors));
//     }
//   } catch (err) {
//     console.log('issuePasswordTokenSaga', err);
//   }
// }

// function* signUpUserSaga(action) {
//   try {
//     const { user } = action;
//     const userResponse = yield call(signUpUserService, user);
//     if (!userResponse.error && !userResponse.errors) {
//       yield put(actions.signUpUserSuccess());
//       yield put(actions.changeCurrentUser(user));
//     } else {
//       yield put(actions.failUserSignUp(userResponse.error, userResponse.errors));
//     }
//   } catch (error) {
//     console.log('signUpUserSaga', error);
//   }
// }

// GET USER ACCOUNT DATA
function* userAccountDataRequestedSaga() {
  console.log('saga');

  try {
    const userAccountDataResponse = yield call(userAccountDataRequestedService);
    if (!userAccountDataResponse.error) {
      yield put(actions.userAccountDataRetrieved(userAccountDataResponse));
    }
  } catch (error) {
    console.log('userAccountDataRequestedSaga', error);
  }
}

// function* retrieveUserPrefillDataSaga(action) {
//   const { token } = action;
//   try {
//     const userAccountDataResponse = yield call(retrieveUserPrefillDataService, token);
//     if (!userAccountDataResponse.error) {
//       yield put(actions.userAccountDataRetrieved(userAccountDataResponse));
//     }
//   } catch (error) {
//     console.log('retrieveUserPrefillDataSaga', error);
//   }
// }

// function* confirmInviteSaga(action) {
//   const { user } = action;
//   try {
//     const userAccountDataResponse = yield call(confirmInviteService, user);
//     if (!userAccountDataResponse.errors) {
//       yield put(actions.userAccountDataRetrieved(userAccountDataResponse));
//       yield put(actions.signUpUserSuccess());
//     } else {
//       yield put(actions.failUserSignUp(userAccountDataResponse.errors[0]));
//     }
//   } catch (error) {
//     console.log('confirmInviteSaga', error);
//   }
// }

// function* updateUserAccountDataSaga(action) {
//   try {
//     const updateUserAccountDataResponse = yield call(
//       updateUserAccountDataService, action.userAccountData,
//     );
//     if (!updateUserAccountDataResponse.error) {
//       yield put(actions.userAccountDataUpdated(updateUserAccountDataResponse));
//     }
//   } catch (err) {
//     yield put(actions.userAccountDataUpdateFailed(err));
//   }
// }

// function* updateUserPasswordSaga(action) {
//   try {
//     const updateUserPasswordResponse = yield call(updateUserPasswordService,
//       action.userPasswordData);
//     if (!updateUserPasswordResponse.error && updateUserPasswordResponse.errors < 0) {
//       yield put(actions.userPasswordUpdated());
//     } else {
//       yield put(actions.userPasswordUpdateFailed(updateUserPasswordResponse.error, updateUserPasswordResponse.errors));
//     }
//   } catch (err) {
//     console.log('updateUserPasswordSaga', err);
//   }
// }


export function* userAccountDataRequestedWatcher() {
  yield takeEvery(actions.USER_ACCOUNT_DATA_REQUESTED, userAccountDataRequestedSaga);
}


// export function* signUpUserWatcher() {
//   yield takeEvery(actions.SIGNUP_USER, signUpUserSaga);
// }

// export function* retrieveUserPrefillDataWatcher() {
//   yield takeEvery(actions.GET_USER_PREFILL_DATA, retrieveUserPrefillDataSaga);
// }

// export function* confirmInviteWatcher() {
//   yield takeEvery(actions.CONFIRM_INVITE, confirmInviteSaga);
// }

// export function* issuePasswordTokenWatcher() {
//   yield takeEvery(actions.USER_PASSWORD_TOKEN_ISSUED, issuePasswordTokenSaga);
// }

// export function* resetPasswordWithTokenWatcher() {
//   yield takeEvery(actions.USER_PASSWORD_RESET, resetPasswordWithTokenSaga);
// }

// export function* updateUserAccountDataWatcher() {
//   yield takeEvery(actions.UPDATE_USER_ACCOUNT_DATA, updateUserAccountDataSaga);
// }

// export function* updateUserPasswordWatcher() {
//   yield takeEvery(actions.UPDATE_USER_PASSWORD, updateUserPasswordSaga);
// }
