import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import TimePicker from 'rc-time-picker';
import BusinessSelect from '../../home/componets/BusinessSelect';

import 'rc-time-picker/assets/index.css';

import * as businessActions from '../../../reducers/businessReducer';

// eslint-disable-next-line react/prefer-stateless-function
class BusinessDetailsStep extends React.Component {
  render() {
    const { business, updateBusinessStore } = this.props;
    const {
      businessName,
      businessType,
      businessEmail,
      addressLine1,
      addressLine2,
      businessCity,
      postcode,
      country,
      phone,
      openTime,
      closeTime,
    } = business;
    return (
      <div className="business-get-started-stepper-wrapper">
        <div className="business-get-started-stepper-block">
          <TextField
            id="business-name"
            variant="outlined"
            label="Business Name"
            value={businessName}
            required
            fullWidth
            margin="normal"
            onChange={(event) => updateBusinessStore(
              { businessName: event.target.value },
            )}
          />
          <BusinessSelect
            businessType={businessType}
            updateGetStartedStore={updateBusinessStore}
          />
          {/* <TimeSelect
            timeValue={openTime}
            updateGetStartedStore={updateBusinessStore} /> */}
          <TextField
            id="business-email"
            label="Business Email Address"
            value={businessEmail}
            onChange={(event) => updateBusinessStore({ businessEmail: event.target.value })}
            margin="normal"
            variant="outlined"
            fullWidth
          />
          <TextField
            id="phone"
            label="Phone Number"
            value={phone}
            onChange={(event) => updateBusinessStore({ phone: event.target.value })}
            margin="normal"
            variant="outlined"
            fullWidth
          />
          {/* <TimePicker
            className="get-started-time-picker"
            value={openTime}
            showSecond={false}
            use12Hours={false}
            hideDisabledOptions
            minuteStep={15}
            placeholder="Opening time *"
            onChange={(event) => updateBusinessStore({ openTime: event })}

          />
          <TimePicker
            className="get-started-time-picker"
            value={closeTime}
            showSecond={false}
            use12Hours={false}
            hideDisabledOptions
            minuteStep={15}
            placeholder="Closing time *"
            onChange={(event) => updateBusinessStore({ closeTime: event })}

          /> */}
          {/* <TextField
            id="business-opentime"
            label="Opening Time"
            value={openTime}
            onChange={(event) => updateBusinessStore({ openTime: event.target.value })}
            margin="normal"
            variant="outlined"
            fullWidth
          />
          <TextField
            id="business-closetime"
            label="Closing Time"
            value={closeTime}
            onChange={(event) => updateBusinessStore({ closeTime: event.target.value })}
            margin="normal"
            variant="outlined"
            fullWidth
          /> */}
        </div>
        <div className="business-get-started-stepper-block">
          <TextField
            id="business-address-1"
            label="Address Line 1"
            value={addressLine1}
            onChange={(event) => updateBusinessStore({ addressLine1: event.target.value })}
            margin="normal"
            variant="outlined"
            required
            fullWidth
          />
          <TextField
            id="business-address-2"
            label="Address Line 2"
            value={addressLine2}
            onChange={(event) => updateBusinessStore({ addressLine2: event.target.value })}
            margin="normal"
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            id="business-city"
            variant="outlined"
            label="Business City"
            fullWidth
            required
            margin="normal"
            onChange={(event) => updateBusinessStore({ businessCity: event.target.value })}
          />
          <TextField
            id="postcode"
            label="Postcode"
            value={postcode}
            onChange={(event) => updateBusinessStore({ postcode: event.target.value })}
            margin="normal"
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            id="country"
            label="Country"
            value={country}
            onChange={(event) => updateBusinessStore({ country: event.target.value })}
            margin="normal"
            variant="outlined"
            fullWidth
            required
          />
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business.business,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateBusinessStore: (data) => (businessActions.updateBusinessStore(data)),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BusinessDetailsStep);
