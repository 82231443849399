import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
// import * as actions from '../../reducers/calendarReducer';
// import * as sessionActions from '../../reducers/sessionReducer';
// import BookingCreationDialog from '../bookings/components/BookingCreationDialog';
import '../../styles/dayschedule.css';
import CalendarSlot from '../../containers/calendar/CalendarSlot';
// import DateWithSitesWrapper from '../../components/Date/DateWithSitesWrapper';

class Calendar extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    const {
      selectedSiteId, selectedDate, sites, changeSelectedSiteId, getCalendarUsage,
    } = this.props;

    if (selectedSiteId === null && sites.length > 0) {
      changeSelectedSiteId(sites[0].id);
    }
  }

  configureHeaderHours() {
    const open = '10:00'
    const close = '19:00'
    const startOfDay = moment.utc(open, 'HH:mm').startOf('hour');
    const endOfDay = moment.utc(close, 'HH:mm').endOf('hour');
    const hours = [];
    let hour = startOfDay;

    while (hour <= endOfDay) {
      const hourObject = {
        hour,
      };
      hours.push(hourObject);
      hour = hour.clone().add(1, 'h');
    }
    return hours;
  }

  configureHoursSegment() {
    const open = '10:00'
    const close = '19:00'
    const startOfDay = moment.utc(open, 'HH:mm').startOf('hour');
    const endOfDay = moment.utc(close, 'HH:mm').endOf('hour');
    const hours = [];
    let hour = startOfDay;

    while (hour <= endOfDay) {
      const hourObject = {
        hour,
      };
      hours.push(hourObject);
      hour = hour.clone().add(15, 'm');
    }
    return hours;
  }

  changeSite(event) {
    const siteId = event.target.value;
    const { changeSelectedSiteId, getCalendarUsage } = this.props;
    changeSelectedSiteId(siteId);
    getCalendarUsage();
  }

  render() {
    const { } = this.props;
    const employees = ['Sean', 'Martin ']
    const open = '10:00'
    const close = '19:00'

    const hours = this.configureHoursSegment();
    const headerHours = this.configureHeaderHours();

    return (
      <div className="dayschedule-main">
        <div className="dashboard-main-title">
          Schedule
        </div>
        <div style={{ marginTop: '10px', display: 'block' }}>
          {open
            ? (
              <div className="fixed_header">
                <div className="thead">
                  <div className="tr">
                    <div className="th"><p /></div>
                    {headerHours.map((hour) => (
                      <div className="th">
                        <div className="CalendarHeader">
                          <div className="CalendarHeaderSubSub">{moment(hour.hour).format('h')}</div>
                          <div className="CalendarHeaderSubSub-2">{moment(hour.hour).format('A')}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <div className="tbody">
                    <div className="tr">
                      {employees && employees.map((employee) => (
                        <div>
                          <div
                            className="td"
                            style={{
                              width: '180px',
                              position: 'sticky',
                              borderTop: '1px #fff solid',
                              borderBottom: '1px #fff solid',
                              fontWeight: 'bold',
                              backgroundColor: '#eee',
                              paddingLeft: '5px',
                            }}
                          >
                            <p style={{ marginLeft: '5px', marginTop: 10 }}>{employee}</p>
                          </div>
                          {headerHours.map((hour, i) => (
                            <CalendarSlot hidden={false} facilityId={employee.id} hour={hour} key={i} />
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )
            : <div>Loading....</div>}
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  employees: PropTypes.shape().isRequired,
  getCompanySites: PropTypes.func.isRequired,
  getCalendarUsage: PropTypes.func.isRequired,
  changeSelectedSiteId: PropTypes.func.isRequired,
  selectedSiteId: PropTypes.string.isRequired,
  calendarUsage: PropTypes.shape().isRequired,
  sites: PropTypes.shape().isRequired,
  selectedDate: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  return {
  };
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
