/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as currentUserActions from '../../../shared/reducers/currentUserReducer';

class BlankTemplate extends React.Component {
  render() {
    return (
      <div className="dashboard-block-2">
        <div className="dashboard-block-1-title">
          Employee summary
        </div>
        <div className="dashboard-block-1-body">
          17 total appointments today
        </div>
        <div className="dashboard-upnext-card">
          <div className="dashboard-upnext-card-left">
            <div className="dashboard-upnext-card-image-2">S</div>
          </div>
          <div className="dashboard-upnext-card-right">
            <div className="dashboard-upnext-card-desctiption">
              Sean - 10 appointments
            </div>
          </div>
        </div>
        <div className="dashboard-upnext-card">
          <div className="dashboard-upnext-card-left">
            <div className="dashboard-upnext-card-image-2">M</div>
          </div>
          <div className="dashboard-upnext-card-right">
            <div className="dashboard-upnext-card-desctiption">
              Martin - 7 appointments
            </div>
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          margin="normal"
          fullWidth
          style={{
            color: '#fff',
            height: '40px',
            borderRadius: '5px',
            boxShadow: '0 1px 4px 0 rgba(21, 27, 38, 0.2)',
            marginTop: '13px',
          }}
        >
          View Employees
        </Button>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  push: () => push('/about-us'),
  updateGetStartedStore: (data) => (currentUserActions.updateGetStartedStore(data)),
}, dispatch);

export default connect(null, mapDispatchToProps)(BlankTemplate);
